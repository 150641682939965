<template>
  <card
    internal-card="container card-container"
  >
    <card
      slot="body"
      external-card="card container material-card card-modal"
      internal-card="material-card-content"
      url-help=""
    >
      <div
        slot="header"
        class="columns"
        style="flex:1;flex-direction: row;justify-content: space-between"
      >
        <card
          internal-card="modal-card-body column"
          style="flex:2.5"
        >
          <p
            slot="header"
            class="subtitle"
          >
            <b>Change update hours</b>
          </p>
          <div slot="body">
            <p
              style="margin-bottom: 20px; "
            >
              Set update hours to let us know when you typically not use the devices
              associated with the device group.
            </p>
            <b-field>
              <b-checkbox v-model="settingUpdateTime.defaultTime">
                Allow Boardgent to select the best hours.
              </b-checkbox>
            </b-field>
            <div
              v-show="!settingUpdateTime.defaultTime"
            >
              <b-field label="Start time">
                <b-timepicker
                  v-model="settingUpdateTime.startTime"
                  v-validate="'required'"
                  class="picker-time"
                  name="startTime"
                  inline
                  :increment-minutes="60"
                  @input="validateDates"
                />
              </b-field>
              <b-field label="End time">
                <b-timepicker
                  v-model="settingUpdateTime.endTime"
                  v-validate="'required|differentDate'"
                  class="picker-time"
                  name="endTime"
                  inline
                  :increment-minutes="60"
                  @input="validateDates"
                />
              </b-field>
              <span
                v-show="errors.has('endTime')"
                class="help is-danger"
              >{{ errors.first('endTime') }}</span>
            </div>
          </div>
        </card>
      </div>
      <footer
        slot="external-footer"
        class="card-footer has-text-centered padding-card-footer"
      >
        <button
          v-if="$parent.canCancel"
          class="button is-outlined is-primary downloadButton force-right"
          @click="$parent.close()"
        >
          Cancel
        </button>

        <button
          class="button is-primary downloadButton force-right"
          @click="confirm()"
        >
          Save
        </button>
      </footer>
    </card>
  </card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';
import ErrorMixin from '@/mixins/error';

export default {
  name: 'SettingUpdateTimeModal',
  components: {
    card,
  },
  mixins: [ErrorMixin],
  props: {
  },
  data() {
    return {
      settingUpdateTime: { defaultTime: true, startTime: null, endTime: null },
      hoursIntervalError: false,
      boardgentDefaultTime: { start: new Date(2021, 0, 1, 8, 0), end: new Date(2021, 0, 1, 10, 0) },
    };
  },
  computed: {
    ...mapGetters('employee-profiles', ['getUpdateHours']),
  },
  created() {
    this.settingUpdateTime.defaultTime = this.getUpdateHours.defaultTime;
    this.settingUpdateTime.startTime = this.getUpdateHours.startTime
      ? new Date(this.getUpdateHours.startTime) : this.boardgentDefaultTime.start;
    this.settingUpdateTime.endTime = this.getUpdateHours.endTime
      ? new Date(this.getUpdateHours.endTime) : this.boardgentDefaultTime.end;
    this.$validator.extend(
      'differentDate', {
        getMessage: 'Both times, initial and final dates need to be different.',
        validate: (value) => {
          if (value.getTime() === this.settingUpdateTime.startTime.getTime()) {
            return false;
          }
          return true;
        },
      },
    );
  },
  methods: {
    ...mapActions('employee-profiles', { updateEmployeeProfile: 'patch' }),
    ...mapActions('employee-profiles', ['updateHours']),
    confirm() {
      this.$validator.validateAll();
      if (!this.errors.has('startTime') && !this.errors.has('endTime')) {
        this.updateHours(this.settingUpdateTime);
        this.$parent.close();
      }
    },
    validateDates() {
      this.$validator.validateAll();
    },
  },
};
</script>

<style>
.modal .card-container{
    max-width: 350px;
}
.modal footer button:nth-child(2){
    margin-left: 1rem;
}
@media (max-width: 768px) {
 .modal {
    padding: 1rem;
 }
}
.progress-container {
  margin: auto;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
}

.picker-time .dropdown-content{
  box-shadow: initial;
}

.picker-time .dropdown-content:hover{
  box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 3px 1px , rgba(60, 64, 67, 0.1) 0px 2px 8px 4px;
}
</style>

import { mapActions } from 'vuex';
import sliceArray from '@/helpers/sliceArray';
import snackBarMessage from '@/helpers/snackBarMessage';

export default {
  name: 'ExecutionsMixin',
  methods: {
    ...mapActions('executions', { createExecution: 'create' }),
    ...mapActions('execution-groups', { createExecutionGroup: 'create' }),
    ...mapActions('execution-groups', { removeExecutionGroup: 'remove' }),
    async createExecutions(companyId, userId, allDeviceIds, action, parameters) {
      if (allDeviceIds.length <= 0) {
        return false;
      }
      this.executingAction = true;
      const executionGroup = await this.createExecutionGroup({
        action,
        parameters,
        companyId,
        userId,
      });
      let results = [];
      try {
        const deviceIdGroups = sliceArray(allDeviceIds, 50);
        const executionResults = [];
        let executionPopulated = [];

        deviceIdGroups.forEach((deviceIdGroup) => {
          executionPopulated = deviceIdGroup.map((deviceId) => ({
            deviceId,
            companyId,
            executionGroupId: executionGroup.id,
            action,
            userId,
          }));
          executionResults.push(this.createExecution([executionPopulated]));
          executionPopulated = [];
        });
        results = await Promise.all(executionResults);
      } catch (error) {
        await this.removeExecutionGroup([
          executionGroup.id,
          {
            query: {
              companyId,
            },
          },
        ]);
        throw error;
      }
      this.executingAction = false;
      return {
        executionGroupId: executionGroup.id,
        results,
      };
    },
    // We pass the companyId as parameter, instead of reading from the store every time to prevent
    // actions created with another company when the user changes the company in the UI
    async executeAction(id, companyId) {
      try {
        let parameters = {};
        if (this.parametersToExecute) {
          parameters = JSON.parse(JSON.stringify(this.parametersToExecute));
        } else if (this.selectedActionParameters) {
          this.selectedActionParameters.map((element) => {
            if (element.isAdvance) {
              parameters[element.name] = element.checkbox.default ? element.default : undefined;
            } else {
              parameters[element.name] = element.default === false || element.default
                ? element.default : undefined;
            }
            return true;
          });
        }
        // MPS Actions only run with AMT connected,
        // that means that the onlyOnlineDevices parameter is ignored
        if (!this.selectAction.serverSide && !this.selectAction.serverAndClientSide
            && !parameters.onlyOnlineDevices) {
          parameters.onlyOnlineDevices = this.onlyOnlineDevices;
        }
        const devicesId = this.selectedDevices;
        const execution = await this.createExecutions(
          companyId, this.$store.getters['auth/user'].id, devicesId, id, parameters,
        );
        let redirectTo = 'executiondevices';
        if (this.currentRouteName === 'executedevice') redirectTo = 'executiondevice';
        this.$router.push({
          name: redirectTo,
          params: { executionGroupId: execution.executionGroupId, page: 1 },
        });
      } catch (err) {
        snackBarMessage.showError(err);
        this.onExecution = false;
        this.activeTab = 0;
        throw err;
      }
    },
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticStyle:{"overflow":"visible"},attrs:{"slot":"external-body","external-card":"material-card-content","internal-card":"material-card","style-internal-card":"flex:auto"},slot:"external-body"},[_c('div',{attrs:{"slot":"body","id":"wrapper"},slot:"body"},[_c('gmap-map',{ref:"map",staticClass:"map",staticStyle:{"height":"85vh","z-index":"0"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
        'minZoom': 3,
        'maxZoom': 20,
        'streetViewControl': false,
        'mapTypeControl': false,
        'clickableIcons': false
      }},on:{"click":function($event){return _vm.addPath($event)}}},[_vm._l((_vm.perimeters.filter(
          (x) => x.type.dbValue === _vm.locationPerimeterType.POLYGON.value.dbValue)),function(perimeter,index){return _c('gmap-polygon',{key:index,ref:"polygon",refInFor:true,attrs:{"paths":perimeter.paths,"editable":perimeter.value === _vm.selectedPerimeter},on:{"paths_changed":function($event){return _vm.updateEdited($event)}}})}),_vm._l((_vm.perimeters.filter((x) =>
          x.type.dbValue === _vm.locationPerimeterType.MARKER.value.dbValue
          && x.center.lat && x.center.lng
          && x.radius)),function(perimeter){return _c('gmap-circle',{key:perimeter.name,attrs:{"center":{lat: parseFloat(perimeter.center.lat), lng: parseFloat(perimeter.center.lng)},"radius":parseFloat(perimeter.radius),"visible":true,"editable":perimeter.value === _vm.selectedPerimeter,"options":{'fillColor':'red', 'fillOpacity':0.2}},on:{"radius_changed":function($event){return _vm.radiusChanged($event)},"center_changed":function($event){return _vm.centerChanged($event)}}})})],2),_c('manage-zones',{attrs:{"perimeters":_vm.perimeters,"on-update-parameters":_vm.updatePerimeters,"on-save-perimeters":_vm.saveMap}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
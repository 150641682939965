function sliceArray(originalArray, maxSize) {
  const arrayLength = originalArray.length;
  const finalArray = [];

  for (let index = 0; index < arrayLength; index += maxSize) {
    finalArray.push(originalArray.slice(index, index + maxSize));
  }

  return finalArray;
}

export default sliceArray;

<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item
          :to="{ name: 'groups'}"
          tag="router-link"
        >
          Device groups
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          <span
            v-if="this.$route.params.profileId &&
              this.$route.params.duplicate "
          >
            Duplicate {{ newProfile.prevName }}</span>
          <span
            v-else-if="this.$route.params.profileId &&
              !this.$route.params.duplicate "
          >
            {{ newProfile.name }}</span>
          <span v-else>Create new group</span>
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
      internal-card="material-card material-card-content"
      url-help=""
    >
      <b-tabs
        slot="body"
        v-model="activeTab"
      >
        <b-tab-item
          label="Settings"
        >
          <b-field
            :type="errors.has('name') ? 'is-danger':''"
            label="Name"
          >
            <b-input
              v-if="this.$route.params.profileId && !this.$route.params.duplicate"
              v-model="newProfile.name"
              v-validate="'required'"
              :disabled="newProfile.isDefaultProfile"
              name="name"
              type="name"
            />
            <b-input
              v-else
              v-model="newProfile.name"
              v-validate="'required'"
              name="name"
              type="name"
              autocomplete="off"
            />
          </b-field>
          <span
            v-show="errors.has('name')"
            class="help is-danger"
          >{{ errors.first('name') }}</span>
          <div
            v-for="parentSetting in parentSettings.filter(
              (parentSettingSearch) => !parentSettingSearch.value.parent)"
            :key="parentSetting.value.id"
          >
            <p
              class="field mt-5"
            >
              <b>{{ parentSetting.value.name }}</b>
            </p>
            <div
              v-for="childSetting in childSettings.filter(
                (childSettingSearch) => childSettingSearch.value.parent)"
              v-show="!loadingData"
              :key="childSetting.value.id"
              class="field"
            >
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.withoutSwitch"
              >
                <div
                  style="display: flex;justify-content: space-between;align-items: center;
                flex-wrap: wrap"
                >
                  <p style="text-align: start; width: 280px">
                    Clean <strong>{{ childSetting.value.name }}</strong> folder automatically
                  </p>
                  <div>
                    <b-dropdown
                      v-model="childSetting.value.filesOlderThan"
                      aria-role="list"
                      class="inner-element mt-2"
                      :position="'is-bottom-right'"
                      scrollable
                    >
                      <template #trigger="{ active }">
                        <b-button
                          style="background-color: #632d8e;color: #fff;width: 200px;"
                          :label="childSetting.value.filesOlderThan
                            ? `Older than ${frequenciesArray.find(x =>
                              x.id === childSetting.value.filesOlderThan).name}`
                            :'Off'"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="frequency in frequenciesArray"
                        :key="frequency.id"
                        aria-role="listitem"
                        :value="frequency.id"
                      >
                        {{ getTextOptions(frequency) }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <!-- <b-dropdown
                      v-if="childSetting.value.filesOlderThan !== frequencyEnum.OFF.value.id"
                      v-model="childSetting.value.typeOfDelete"
                      aria-role="list"
                      class="inner-element mt-2"
                      :position="'is-bottom-right'"
                      scrollable
                    >
                      <template #trigger="{ active }">
                        <b-button
                          style="background-color: #632d8e;color: #fff;width: 200px;"
                          :label="childSetting.value.typeOfDelete
                            ? `${typeOfDeleteArray.find(x =>
                              x.id === parseInt(childSetting.value.typeOfDelete)).name}`
                            :'Off'"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="type in typeOfDeleteArray"
                        :key="type.id"
                        aria-role="listitem"
                        :value="type.id"
                      >
                        {{ type.name }}
                      </b-dropdown-item>
                    </b-dropdown> -->
                  </div>
                </div>
                <hr>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.canSwitch"
              >
                <b-switch
                  v-model="childSetting.value.enabled"
                  class="first-align"
                  :disabled="!childSetting.value.canSwitch"
                >
                  {{ childSetting.value.name }}
                  <b-tooltip
                    v-if="childSetting.value.tooltip"
                    :label="childSetting.value.tooltip"
                    position="is-right"
                    multilined
                    size="is-medium"
                    type="is-dark"
                  >
                    <b-icon
                      icon="help-circle"
                      size="is-small"
                    />
                  </b-tooltip>
                </b-switch>
                <b-dropdown
                  v-if="childSetting.value.adjustableTime && childSetting.value.enabled"
                  v-model="childSetting.value.selectedFrequency"
                  aria-role="list"
                  class="inner-element mt-2"
                  :position="childSetting.value.id === 'agentUpdate'
                    || childSetting.value.id === 'updateUsers'
                    || childSetting.value.id === 'listSystemUpdates'
                    ? 'is-top-right' : 'is-bottom-right'"
                  scrollable
                >
                  <template #trigger="{ active }">
                    <b-button
                      :label="childSetting.value.selectedFrequency
                        ? `Every ${frequenciesArray.find(x =>
                          x.id === parseInt(childSetting.value.selectedFrequency)).name}`
                        :'Older than'"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="frequency in filterFrequencies(childSetting)"
                    :key="frequency.id"
                    aria-role="listitem"
                    :value="frequency.id"
                  >
                    Every {{ frequency.name }}
                    <span v-if="frequency.id === childSetting.value.defaultFrequency">
                      (recommended)
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-if="childSetting.value.setupTime && childSetting.value.enabled
                    && childSetting.value.canSwitch"
                  style="display: block !important; margin-left: 55px;"
                  @click="openCheckUpdateHourModal"
                >
                  Change update hours
                </b-button>
                <b-button
                  v-if="childSetting.value.enabled && childSetting.value.canSwitch
                    && childSetting.key === profileSettings.locationBounds.key"
                  style="display: block !important; margin-left: 55px;"
                  @click="openLocationBoundsModal"
                >
                  Manage geo-fences
                </b-button>
                <span
                  v-show="locationBoundsError && childSetting.value.enabled
                    && childSetting.key === profileSettings.locationBounds.key"
                  class="help is-danger"
                  style="margin-left: 8%; "
                >Please verify location bounds</span>
                <div
                  v-if="childSetting.value.addApps && childSetting.value.enabled
                    && childSetting.value.canSwitch"
                  style="margin-left: 55px;"
                >
                  <div
                    v-for="app in applications"
                    :key="app"
                  >
                    <b-field
                      style="margin-top: 5px; margin-bottom: 5px;"
                    >
                      <b-input
                        v-model="newProfile.apps[app - 1].name"
                        v-validate="'required'"
                        placeholder="Name"
                        name="application name"
                        expanded
                        type="text"
                        style="max-width: 80%; "
                      />
                      <b-input
                        v-model="newProfile.apps[app - 1].version"
                        placeholder="Version (latest)"
                        name="version"
                        type="text"
                        expanded
                        style="margin-right: 1px; max-width: 20%; "
                      />
                      <button
                        class="button is-primary"
                        @click="removeApp(app - 1)"
                      >
                        <b-icon
                          icon="delete"
                        />
                      </button>
                    </b-field>
                  </div>
                  <span
                    v-show="errors.has('application name')"
                    class="help is-danger"
                  >{{ errors.first('application name') }}</span>
                  <b-button
                    class="is-primary"
                    size="is-small"
                    icon-left="plus"
                    @click="addApp"
                  >
                    Add app
                  </b-button>
                </div>
                <div
                  v-if="childSetting.value.id ==='bootOrder' && childSetting.value.enabled"
                >
                  <div
                    v-for="(element) in bootOrderSettings"
                    :key="element.value"
                    class="inner-element"
                  >
                    <p class="mb-1">
                      {{ element.name }}
                    </p>
                    <b-field
                      v-for="field in element.devicesAmount"
                      :key="field"
                      :type="{ 'is-danger': element.devices[field - 1] === '' }"
                      :message="{ 'The boot order name is required':
                        element.devices[field - 1] === '' }"
                    >
                      <b-input
                        v-model="element.devices[field - 1]"
                        expanded
                        type="text"
                        placeholder="Name"
                        style="margin-right: 2px; "
                      />
                      <button
                        class="button is-primary"
                        @click="removeBootOrder(field - 1, element)"
                      >
                        <b-icon
                          icon="delete"
                        />
                      </button>
                    </b-field>
                    <b-field style="margin-bottom:14px">
                      <b-button
                        class="is-primary"
                        size="is-small"
                        icon-left="plus"
                        @click="addBootOrder(element)"
                      >
                        Add boot device
                      </b-button>
                    </b-field>
                  </div>
                </div>
                <div
                  v-if="childSetting.value.id ==='freezeExclusions' && childSetting.value.enabled"
                >
                  <p class="mb-1">
                    File Exclusions:
                  </p>
                  <b-field
                    v-for="field in uwfExclusions.pathsAmount"
                    :key="field"
                    :type="{ 'is-danger': uwfExclusions.paths[field - 1] === '' }"
                    :message="{ 'The boot order name is required':
                      uwfExclusions.paths[field - 1] === '' }"
                  >
                    <b-input
                      v-model="uwfExclusions.paths[field - 1]"
                      expanded
                      type="text"
                      placeholder="Path"
                      style="margin-right: 2px; "
                    />
                    <button
                      class="button is-primary"
                      @click="removeExclusion(field - 1)"
                    >
                      <b-icon
                        icon="delete"
                      />
                    </button>
                  </b-field>
                  <b-field style="margin-bottom:14px">
                    <b-button
                      class="is-primary"
                      size="is-small"
                      icon-left="plus"
                      @click="addExclusion()"
                    >
                      Add exclusion
                    </b-button>
                  </b-field>
                </div>
                <b-dropdown
                  v-if="childSetting.value.dropdownOptions && childSetting.value.enabled"
                  v-model="childSetting.value.selectedOption"
                  v-validate="'required'"
                  :name="childSetting.value.id"
                  aria-role="list"
                  class="inner-element mt-2"
                >
                  <template #trigger="{ active }">
                    <b-button
                      :label="childSetting.value.selectedOption ? childSetting.value.dropdownOptions
                        .find((x) => x.value === childSetting.value.selectedOption).name
                        : 'Select an option'"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="option in childSetting.value.dropdownOptions"
                    :key="option.value"
                    aria-role="listitem"
                    :value="option.value"
                  >
                    {{ option.name }}
                  </b-dropdown-item>
                </b-dropdown>
                <span
                  v-show="errors.has(childSetting.value.id)"
                  class="help is-danger"
                  style="margin-left: 8%; "
                >
                  Option required
                </span>
                <div
                  v-if="childSetting.value.chooseWallpaper && childSetting.value.enabled
                    && childSetting.value.canSwitch"
                >
                  <b-upload
                    v-show="!wallpaperUrl"
                    v-model="wallpaperFile"
                    v-validate="'required'"
                    class="file-label"
                    style="margin-left: 55px;margin-top: 12px;"
                    name="background image"
                    accept="image/*"
                    @input="uploadWallpaperResult"
                  >
                    <span class="file-cta">
                      <b-icon
                        class="file-icon"
                        icon="upload"
                      />
                      <span class="file-label">
                        {{ wallpaperFile ? wallpaperFile.name : "Click to upload" }}
                      </span>
                    </span>
                  </b-upload>
                  <div
                    v-if="wallpaperUrl"
                    style="margin-left: 55px;width: 200px"
                  >
                    <b-tooltip
                      type="is-dark"
                      position="is-right"
                      label="Remove"
                      style="position: relative;left: 185px;margin: -20px;top: 15px;"
                    >
                      <b-button
                        type="is-danger"
                        rounded
                        @click="removeWallpaper"
                      >
                        <b-icon
                          icon="close"
                        />
                      </b-button>
                    </b-tooltip>
                    <img
                      :src="wallpaperUrl"
                      width="200"
                    >
                  </div>
                  <span
                    v-show="errors.has('background image')"
                    class="help is-danger"
                  >{{ errors.first('background image') }}</span>
                </div>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.id === profileSettings.windowsAdminPassword.value.id"
                class="mt-2"
              >
                <div class="columns second-align mt-2">
                  <div
                    class="column"
                    style="padding-left: 0rem !important;"
                  >
                    <form @submit.prevent="event.preventDefault();">
                      <p class="mb-1">
                        Account to manage (Optional)
                      </p>
                      <b-input
                        v-model="localAccount.AccountName"
                        v-validate="'max:83'"
                        placeholder="If not specified we use the built-in Administrator account"
                        name="localAcName"
                        type="text"
                        style="max-width:650px"
                        autocomplete="off"
                        @input="blockResetKeyChain()"
                      />
                      <span
                        v-show="errors.has('localAcName')"
                        class="help is-danger"
                      >
                        The local account name may not be greater than 83 characters.
                      </span>
                      <b-field
                        :style="'margin: 10px 0px'"
                      >
                        <b-checkbox
                          v-model="localAccount.resetLoginKeyChain"
                          :disabled="disableResetLoginKeyChain"
                        >
                          Allow reset login keychain (macOS)
                          <b-tooltip
                            label="A new login keychain will be created and you will lose
                          the passwords saved on the computer"
                            position="is-right"
                            multilined
                            size="is-large"
                            type="is-dark"
                          >
                            <b-icon
                              icon="help-circle"
                              size="is-small"
                            />
                          </b-tooltip>
                        </b-checkbox>
                      </b-field>
                    </form>
                    <form @submit.prevent="event.preventDefault();">
                      <p
                        v-if="!localAccount.resetLoginKeyChain"
                        class="mb-1"
                      >
                        Password before Boardgent (macOS)
                      </p>
                      <b-input
                        v-if="!localAccount.resetLoginKeyChain"
                        v-model="localAccount.DefaultPassword"
                        placeholder="Initial password"
                        name="localAcPwd"
                        type="password"
                        :password-reveal="localAccount.DefaultPassword != ''
                          && localAccount.DefaultPassword != undefined"
                        style="max-width:650px"
                        autocomplete="off"
                      />
                    </form>
                  </div>
                </div>
                <div class="columns second-align mt-1">
                  <div class="centerVertically">
                    <b-switch
                      v-model="localAccount.AutoChange"
                    >
                      Change password automatically
                      <b-tooltip
                        v-if="childSetting.value.tooltip"
                        :label="childSetting.value.tooltip"
                        position="is-right"
                        multilined
                        size="is-medium"
                        type="is-dark"
                      >
                        <b-icon
                          icon="help-circle"
                          size="is-small"
                        />
                      </b-tooltip>
                    </b-switch>
                    <b-dropdown
                      v-if="localAccount.AutoChange"
                      v-model="localAccount.PasswordAge"
                      aria-role="list"
                      scrollable
                      position="is-top-right"
                    >
                      <template #trigger="{ active }">
                        <b-button
                          :label="localAccount.PasswordAge ? `Every ${frequenciesArray.find(
                            x => x.minutes === localAccount.PasswordAge).name}` : 'Days'"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="frequency in frequenciesOS"
                        :key="frequency.id"
                        aria-role="listitem"
                        :value="frequency.minutes"
                        :disabled="freePlan
                          && frequency.minutes < frequencyEnum.TWENTY_FOUR_HOURS.value.minutes"
                      >
                        Every {{ frequency.name }}
                        <span
                          v-if="frequency.id
                            === frequencyEnum.TWENTY_FOUR_HOURS.value.id"
                        >(recommended)</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="columns second-align mt-2">
                  <b-switch v-model="localAccount.changeOnReveal.enabled">
                    Change password automatically when it is revealed
                  </b-switch>
                  <b-dropdown
                    v-if="localAccount.changeOnReveal.enabled"
                    v-model="localAccount.changeOnReveal.frequency"
                    aria-role="list"
                    scrollable
                    position="is-top-right"
                  >
                    <template #trigger="{ active }">
                      <b-button
                        :label="localAccount.changeOnReveal.frequency ? `In
                          ${getRightFrequencyArticle(localAccount.changeOnReveal.frequency)}
                          ${frequenciesArray
                        .find(x => x.minutes === localAccount.changeOnReveal.frequency)
                        .name}`:'Days'"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                      />
                    </template>
                    <b-dropdown-item
                      v-for="frequency in frequenciesOS"
                      :key="frequency.id"
                      aria-role="listitem"
                      :value="frequency.minutes"
                      :disabled="freePlan
                        && frequency.minutes < frequencyEnum.TWENTY_FOUR_HOURS.value.minutes"
                    >
                      In {{ getRightFrequencyArticle(frequency.minutes) }}
                      {{ frequency.name }}
                      <span v-if="frequency.id === frequencyEnum.TWENTY_FOUR_HOURS.value.id">
                        (recommended)
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="columns second-align mt-2">
                  <div
                    class="column"
                    style="padding-left: 0rem !important;"
                  >
                    <p class="mb-1">
                      Password length
                    </p>
                    <b-field>
                      <b-numberinput
                        v-model="localAccount.PasswordLength"
                        v-validate="'between:8,64'"
                        name="localAccount.PasswordLength"
                        controls-position="compact"
                        min="8"
                        max="64"
                      />
                    </b-field>
                  </div>
                </div>
                <div class="second-align">
                  <p>
                    Password complexity
                  </p>
                  <div
                    class="mt-1 ml-0 columns is-tablet"
                  >
                    <div
                      v-for="(element) in passwordComplexityEnum"
                      v-show="element.value.dbValue !== 0"
                      :key="element.value.dbValue"
                      class="mr-3"
                    >
                      <b-checkbox
                        v-model="localAccount.PasswordComplexity"
                        :native-value="element.value.dbValue"
                      >
                        {{ element.value.name }}
                      </b-checkbox>
                    </div>
                    <span
                      v-show="winPasswordComplexityError"
                      class="help is-danger"
                      style="padding: 0px 12px 20px"
                    >Please select at least one complexity type</span>
                  </div>
                </div>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.id === profileSettings.addDisclaimerOnSignIn.value.id
                  && childSetting.value.enabled"
                class="mt-2"
              >
                <div class="second-align mt-2">
                  <b-field label="Title">
                    <b-input
                      v-model="disclaimerOnSignIn.title"
                      placeholder="Disclaimer title"
                      autocomplete="off"
                      maxlength="200"
                    />
                  </b-field>
                  <b-field label="Message">
                    <b-input
                      v-model="disclaimerOnSignIn.message"
                      placeholder="Disclaimer message"
                      autocomplete="off"
                      maxlength="800"
                    />
                  </b-field>
                </div>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.id === profileSettings.biosPassword.value.id"
                class="mt-2"
              >
                <div class="columns second-align mt-2">
                  <div class="centerVertically">
                    <b-switch
                      v-model="biosPwdMng.AutoChange"
                      v-validate.continues="'biosPasswordValidate'"
                      name="biosAutoChange"
                      @input="validateBiosPassword"
                    >
                      Change password automatically
                      <b-tooltip
                        v-if="childSetting.value.tooltip"
                        :label="childSetting.value.tooltip"
                        position="is-right"
                        multilined
                        size="is-medium"
                        type="is-dark"
                      >
                        <b-icon
                          icon="help-circle"
                          size="is-small"
                        />
                      </b-tooltip>
                    </b-switch>
                    <b-dropdown
                      v-if="biosPwdMng.AutoChange"
                      v-model="biosPwdMng.PasswordAge"
                      aria-role="list"
                      scrollable
                      position="is-top-right"
                    >
                      <template #trigger="{ active }">
                        <b-button
                          :label="biosPwdMng.PasswordAge ? `Every ${frequenciesArray.find(
                            x => x.minutes === biosPwdMng.PasswordAge).name}`:'Days'"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="frequency in frequenciesBios"
                        :key="frequency.id"
                        aria-role="listitem"
                        :value="frequency.minutes"
                      >
                        Every {{ frequency.name }}
                        <span
                          v-if="frequency.id
                            === frequencyEnum.TWENTY_FOUR_HOURS.value.id"
                        >(recommended)</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="columns second-align mt-2">
                  <b-switch
                    v-model="biosPwdMng.changeOnReveal.enabled"
                    v-validate.continues="'biosPasswordValidate'"
                    name="biosChangeOnReveal"
                    @input="validateBiosPassword"
                  >
                    Change password automatically when it is revealed
                  </b-switch>
                  <b-dropdown
                    v-if="biosPwdMng.changeOnReveal.enabled"
                    v-model="biosPwdMng.changeOnReveal.frequency"
                    aria-role="list"
                    scrollable
                    position="is-top-right"
                  >
                    <template #trigger="{ active }">
                      <b-button
                        :label="biosPwdMng.changeOnReveal.frequency ? `In
                          ${getRightFrequencyArticle(biosPwdMng.changeOnReveal.frequency)}
                          ${frequenciesArray
                          .find(x => x.minutes === biosPwdMng.changeOnReveal.frequency)
                        .name}`:'Days'"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                      />
                    </template>
                    <b-dropdown-item
                      v-for="frequency in frequenciesBios"
                      :key="frequency.id"
                      aria-role="listitem"
                      :value="frequency.minutes"
                    >
                      In {{ getRightFrequencyArticle(frequency.minutes) }}
                      {{ frequency.name }}
                      <span v-if="frequency.id === frequencyEnum.TWENTY_FOUR_HOURS.value.id">
                        (recommended)
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="columns second-align mt-1">
                  <div
                    class="column"
                    style="padding-left: 0rem !important;"
                  >
                    <p class="mb-1">
                      Password before Boardgent
                    </p>
                    <b-input
                      v-model="biosPwdMng.DefaultPassword"
                      v-validate="'biosPasswordValidate'"
                      placeholder="Initial password"
                      name="biosPasswordInput"
                      type="password"
                      :password-reveal="biosPwdMng.DefaultPassword != ''
                        && biosPwdMng.DefaultPassword != undefined"
                      style="max-width:650px"
                      autocomplete="off"
                      @input="validateBiosPassword"
                    />
                    <span
                      v-show="errors.has('biosPasswordInput') || errors.has('biosAutoChange')
                        || errors.has('biosChangeOnReveal')"
                      class="help is-danger"
                    >{{ errors.first('biosPasswordInput') || errors.first('biosAutoChange')
                      || errors.first('biosChangeOnReveal') }}</span>
                  </div>
                </div>
                <div class="second-align mt-3">
                  <p class="mb-1">
                    Password length
                  </p>
                  <b-field>
                    <b-numberinput
                      v-model="biosPwdMng.PasswordLength"
                      v-validate="'between:8,64'"
                      name="biosPwdMng.PasswordLength"
                      controls-position="compact"
                      min="8"
                      max="64"
                    />
                  </b-field>
                </div>
                <div class="second-align mt-3">
                  <p>
                    Password complexity
                  </p>
                  <div
                    class="mt-1 ml-0 columns is-tablet"
                  >
                    <div
                      v-for="(element) in passwordComplexityEnum"
                      v-show="element.value.dbValue !== 0"
                      :key="element.value.dbValue"
                    >
                      <b-checkbox
                        v-if="element.value.dbValue !== 4"
                        v-model="biosPwdMng.PasswordComplexity"
                        :native-value="element.value.dbValue"
                        class="mr-3"
                      >
                        {{ element.value.name }}
                      </b-checkbox>
                    </div>
                    <span
                      v-show="biosPasswordComplexityError"
                      class="help is-danger"
                      style="padding: 0px 12px 20px"
                    >Please select at least one complexity type</span>
                  </div>
                </div>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.id === 'agentVersion' && versionsArray.length > 0"
                class="centerVertically"
              >
                {{ childSetting.value.name }}
                <b-dropdown
                  v-model="agentVersion"
                  class="ml-2"
                  aria-role="list"
                  scrollable
                  position="is-top-right"
                >
                  <template #trigger="{ active }">
                    <b-button
                      :label="versionsArray.find(x =>
                        x.value === agentVersion) ? versionsArray.find(x =>
                        x.value === agentVersion).label : 'Select version'"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="version in versionsArray"
                    :key="version.value"
                    aria-role="listitem"
                    :value="version.value"
                  >
                    {{ version.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div
                v-if="(currentCompany.advancedPlan || !childSetting.value.isAdvancedPlan)
                  && childSetting.value.available
                  && childSetting.value.parent === parentSetting.value.id
                  && childSetting.value.id === 'lockDeviceAfterXTime'
                  && childSetting.value.enabled"
              >
                <b-field
                  class="inner-element mt-2"
                >
                  <b-input
                    v-model="lockDeviceAfterXTime.time"
                    v-validate="`required|min_value:0|max_value:${lockDeviceAfterXTime.unit
                      === unitTimeEnum.SECONDS.value.value ? '599940' : '9999'}|numeric|`"
                    name="time input"
                    type="number"
                    placeholder="Time"
                    style="max-width: 100px"
                  />
                  <b-dropdown
                    v-model="lockDeviceAfterXTime.unit"
                    v-validate="'numeric'"
                    name="lockDeviceAfterXTimeUnit"
                  >
                    <template #trigger="{ active }">
                      <b-button
                        :label="lockDeviceAfterXTime.unit === unitTimeEnum.SECONDS.value.value
                          ? unitTimeEnum.SECONDS.value.name : unitTimeEnum.MINUTES.value.name"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                      />
                    </template>
                    <b-dropdown-item :value="unitTimeEnum.SECONDS.value.value">
                      {{ unitTimeEnum.SECONDS.value.name }}
                    </b-dropdown-item>
                    <b-dropdown-item :value="unitTimeEnum.MINUTES.value.value">
                      {{ unitTimeEnum.MINUTES.value.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
                <div>
                  <span
                    v-show="errors.has('time input')"
                    class="help is-danger inner-element"
                  >{{ errors.first('time input') }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item
          label="Modify devices"
        >
          <div
            v-if="!newProfile.isDefaultProfile ||
              this.$route.params.duplicate "
          >
            <b-input
              id="searchInputDeviceField"
              v-model="searchInput.store"
              v-focus
              icon="magnify"
              type="search"
              placeholder="Search devices"
              style="margin-top:15px;"
              expanded
              autocomplete="off"
              @input="search"
            />
            <b-table
              ref="deviceListTable"
              :data="devicesResult"
              :mobile-cards="false"
              :striped="true"
              :paginated="totalData > limitDevices"
              :per-page="limitDevices"
              :loading="devicesResult.length === 0 && isFindDevicesPending"
              :current-page.sync="currentPage"
              :show-detail-icon="false"
              :default-sort="sortField"
              :default-sort-direction="sortDirection"
              hoverable
              scrollable
              class="deviceList"
              backend-pagination
              backend-sorting
              :total="totalData"
              @page-change="changePagination"
              @sort="sortTable"
            >
              <b-table-column>
                <template v-slot:header>
                  <b-checkbox
                    @input="(value) => onCheckAll(value)"
                  />
                </template>
                <template v-slot="props">
                  <b-checkbox
                    :value="checkedRows.map((x)=> x.id).includes(props.row.id)"
                    @input="(value) => onCheckRow(value, props.row)"
                  />
                </template>
              </b-table-column>
              <b-table-column
                v-for="(column, index) in columns"
                :key="index"
                :label="column.title"
                :field="column.field"
                sortable
              >
                <template v-slot="props">
                  {{ props.row[column.field] }}
                </template>
              </b-table-column>
            </b-table>
          </div>
          <b-message
            v-else
            type="is-warning"
          >
            You cannot manually add o remove devices to the default group.
            All devices belong to this group by default until you assign them to another.
          </b-message>
        </b-tab-item>
        <b-tab-item
          :label="`Devices ${checkedRows.length > 0 ?
            '(' + checkedRows.length + ')' : ''}`"
        >
          <b-table
            :data="checkedRows"
            :mobile-cards="false"
            :striped="true"
            :paginated="checkedRows.length > limitSelectedDevices"
            :per-page="limitSelectedDevices"
            :loading="checkedRows.length === 0 && isFindDevicesPending"
            :current-page.sync="currentSelectedPage"
            :show-detail-icon="false"
            hoverable
            scrollable
            class="deviceList"
            backend-pagination
            :total="checkedRows.length"
            @page-change="changeSelectedPagination"
            @sort="sortTable"
          >
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>There are no devices in this group</p>
                </div>
              </section>
            </template>
            <b-table-column
              v-for="(column, index) in columns"
              :key="index"
              :label="column.title"
              :field="column.field"
              sortable
              style="background-color: red"
            >
              <template v-slot="props">
                {{ props.row[column.field] }}
              </template>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
      <b-button
        slot="body"
        :class="{'is-loading': savingData }"
        class="button is-primary force-right"
        @click="validateBeforeSubmit"
      >
        Save
      </b-button>
    </card>
    <b-loading
      v-model="displayLoading"
      :is-full-page="true"
      :parent="this"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import Enum from 'enum';
import { makeFindMixin } from 'feathers-vuex';
import { paramsForServer } from 'feathers-hooks-common';

import card from '@/components/cross/Card.vue';
import settingUpdateTimeModal from '@/components/Profile/SettingUpdateTimeModal.vue';
import locationBoundsModal from '@/components/Profile/LocationBoundsModal.vue';

import ErrorMixin from '@/mixins/error';
import groupsExecution from '@/mixins/executions';
import StripeCompanyInformationMixin from '@/mixins/stripeCompanyInformation';
import RoleMixin from '@/mixins/roles';
import CompanyMixin from '@/mixins/company';

import snackBarMessage from '@/helpers/snackBarMessage';
import toastMessage from '@/helpers/toastMessage';
import parseEnum from '@/helpers/parseEnums';
import EnvironmentSetter from '@/helpers/environmentSetter';
import locationHelpers from '@/helpers/locationHelpers';

import profileSettings from '@/config/profileSettings';
import deviceListColumns from '@/config/deviceListColumns';

import vtulEnums from '../../../cross/index';

const { permissions } = vtulEnums.enum.roles;
const { frequencies, views, parametersToDeleteFiles } = vtulEnums.enum.settings;
const executionEnums = vtulEnums.enum.execution.executeAction;
const { passwordComplexity } = vtulEnums.enum.passwordManager;
const { bootOrderVendors } = vtulEnums.enum.device;
const emitToAgentEnum = vtulEnums.enum.emitEvent.emitToAgent;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['name', 'model', 'hardwareSerial', 'vendor'],
};

export default {
  name: 'CreateProfile',
  metaInfo() {
    return {
      title: this.$route.params.profileId ? 'Edit Group' : 'Create Group',
    };
  },
  components: {
    card,
  },
  mixins: [CompanyMixin, RoleMixin, ErrorMixin, groupsExecution, StripeCompanyInformationMixin, makeFindMixin({ service: 'devices', watch: true })],
  data() {
    return {
      isLoadingImage: false,
      profileSettings,
      sortDirection: 'asc',
      sortField: 'name',
      limitDevices: 20,
      limitSelectedDevices: 20,
      skipDevices: 0,
      skipSelectedDevices: 0,
      currentPage: 1,
      currentSelectedPage: 1,
      newProfile: {},
      parentSettings: [],
      childSettings: [],
      loadingData: true,
      savingData: false,
      applications: 0,
      agentVersion: '0',
      allVersions: [],
      passwordComplexityEnum: passwordComplexity,
      unitTimeEnum: vtulEnums.enum.settings.unitsTime,
      bootOrderSettings: {},
      localAccount: {
        AutoChange: false,
        resetLoginKeyChain: false,
        DefaultPassword: '',
        PasswordAge: frequencies.TWENTY_FOUR_HOURS.value.minutes,
        PasswordLength: 10,
        PasswordComplexity: [1, 2, 3, 4],
        changeOnReveal: {
          enabled: false,
          frequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
        },
      },
      biosPwdMng: {
        DefaultPassword: '',
        AutoChange: false,
        PasswordAge: frequencies.TWENTY_FOUR_HOURS.value.minutes,
        PasswordLength: 10,
        PasswordComplexity: [1, 2, 3],
        changeOnReveal: {
          enabled: false,
          frequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
        },
      },
      bootOrdMng: { devices: [] },
      frequenciesArray: parseEnum.enumToArray(frequencies),
      typeOfDeleteArray: parseEnum.enumToArray(parametersToDeleteFiles),
      versionsArray: [],
      winPasswordComplexityError: false,
      biosPasswordComplexityError: false,
      columns: deviceListColumns.filter((x) => fuseOptions.keys.includes(x.id)),
      searchInput: {
        api: '',
        store: '',
      },
      permissions,
      activeTab: 0,
      selectedDevice: [],
      duplicate: false,
      checkedRows: [],
      originalDeviceRows: [],
      uncheckedRows: [],
      isDefaultProfile: false,
      frequencyEnum: frequencies,
      parametersToDeleteFilesEnum: parametersToDeleteFiles,
      currentView: views.DEVICE_GROUPS.value,
      uwfExclusions: {
        pathsAmount: 1,
        paths: ['C:\\Program Files\\Azure Data Studio'],
      },
      agentServer: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_AGENT'),
      bgApi: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_API'),
      bgApiPath: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_VERSION_PATH'),
      wallpaperFile: null,
      wallpaperUrl: '',
      disableResetLoginKeyChain: false,
      locationBoundsError: false,
      disclaimerOnSignIn: { title: '', message: '' },
      lockDeviceAfterXTime: { unit: '', time: 0 },
    };
  },
  computed: {
    ...mapGetters('employee-profiles', ['getUpdateHours', 'getLocationBounds']),
    versions() {
      return this.allVersions.filter((x) => x.visible || x.version === this.agentVersion);
    },
    currentPaginationQuery() {
      return {
        $limit: this.limitDevices,
        $skip: this.skipDevices,
      };
    },
    queryForSearchDevices() {
      const query = {
        $sort: { [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1] },
        companyId: this.currentCompany.id,
        enabled: true,
      };

      if (this.searchInput.api) {
        const columnsToSearch = [];
        fuseOptions.keys.forEach((column) => {
          columnsToSearch.push({
            [column]: { $ilike: this.searchInput.api },
          });
        });
        query.$or = columnsToSearch;
      }

      if (this.newProfile.isDefaultProfile) {
        query.employeeProfileId = {
          $or: [null, this.newProfile.id],
        };
      }
      return query;
    },
    devicesParams() {
      const queryWithoutSpecialFilters = JSON.parse(JSON.stringify(this.queryForSearchDevices));
      delete queryWithoutSpecialFilters.$client;
      queryWithoutSpecialFilters.$sort = { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 };
      if (queryWithoutSpecialFilters.$or) {
        delete queryWithoutSpecialFilters.$or;
      }
      return {
        query: {
          ...this.currentPaginationQuery,
          ...queryWithoutSpecialFilters,
        },
      };
    },
    devicesFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchDevices,
        },
      };
    },
    devicesResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.devicesLatestQuery || !this.devicesPaginationData.default
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId]
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.devicesLatestQuery;
      let devicesIds = this.devicesPaginationData
        .default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new devices reactive
        let filterDevices = this.devices;
        if (this.searchInput.store) {
          this.updateFuseData(filterDevices);
          filterDevices = this.fuse.search(this.searchInput.store);
        }
        devicesIds = filterDevices.map((x) => x.id).concat(devicesIds);
        devicesIds = [...new Set(devicesIds)];
      }

      const devices = this.findDevicesInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: devicesIds,
          },
          $limit: this.limitDevices,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
          $select: ['id', 'companyId', ...fuseOptions.keys],
        },
      });

      return devices.data;
    },
    totalData() {
      if (!this.devicesLatestQuery || !this.devicesPaginationData.default
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.devicesLatestQuery;
      const { total } = this.devicesPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitDevices);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
    frequenciesBios() {
      return this.frequenciesArray.filter(
        (x) => x.minutes >= frequencies.TWENTY_FOUR_HOURS.value.minutes
        && x.views.includes(this.currentView),
      );
    },
    frequenciesOS() {
      return this.frequenciesArray.filter((x) => x.id >= frequencies.ONE_HOUR.value.id
      && x.views.includes(this.currentView));
    },
    displayLoading() {
      return this.isLoadingImage || this.loadingData;
    },
  },
  watch: {
    currentRole: {
      handler() {
        this.getInitialConfiguration();
      },
      deep: true,
    },
  },
  async created() {
    // Temporally solution while we resolve the Sort issue between the DB and the LocalStorage
    await this.deleteStore();
    bootOrderVendors.enums.forEach((x) => {
      this.$set(this.bootOrderSettings, x.key, {
        key: x.key,
        name: x.value.name,
        value: x.value.dbValue,
        devicesAmount: 0,
        devices: [],
      });
    });
    this.getInitialConfiguration();
    this.$validator.extend(
      'biosPasswordValidate', {
        getMessage: 'Initial Bios password is required.',
        validate: () => {
          if (!this.biosPwdMng.DefaultPassword && (this.biosPwdMng.AutoChange
          || this.biosPwdMng.changeOnReveal.enabled)) {
            return false;
          }
          return true;
        },
      },
    );
    try {
      const agentVersions = await this.axios.get(`${this.agentServer}agentversions`);
      this.versionsArray.push({
        label: 'Latest',
        value: '0',
      });
      agentVersions.data.forEach((version) => {
        this.versionsArray.push({
          label: version,
          value: version,
        });
      });
    } catch (error) {
      toastMessage.showError('We cannot get the agent versions, try later');
    }
  },
  methods: {
    ...mapActions('employee-profiles', {
      createEmployeeProfile: 'create',
      updateEmployeeProfile: 'patch',
      getEmployeeProfile: 'get',
    }),
    ...mapActions('employee-profiles', ['updateHours', 'updateLocationBounds']),
    ...mapActions('devices-totals', { findAllDevices: 'find' }),
    ...mapActions('devices', ['deleteStore']),
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),

    validateBeforeSubmit() {
      this.winPasswordComplexityError = this.localAccount.PasswordComplexity.length < 1;
      this.biosPasswordComplexityError = this.biosPwdMng.PasswordComplexity.length < 1;
      const locationBoundsSetting = this.childSettings.find(
        (setting) => setting.key === profileSettings.locationBounds.key,
      );
      this.locationBoundsError = locationBoundsSetting && locationBoundsSetting.value.enabled
      && (!locationHelpers.verifyPerimeters(this.getLocationBounds)
      || this.getLocationBounds.length < 1);
      this.$validator.validateAll().then((result) => {
        if (result && !this.winPasswordComplexityError && !this.biosPasswordComplexityError
        && !this.locationBoundsError
        && !this.errors.has('biosPasswordValidate')) {
          this.deployConfiguration();
        } else {
          toastMessage.showError('Verify the fields with errors');
        }
      }).catch((error) => { throw new Error(error); });
    },
    validateBiosPassword() {
      this.$validator.validateAll();
    },
    getInitialConfiguration() {
      let profileSettingsArray = new Enum(JSON.parse(JSON.stringify(profileSettings)));
      profileSettingsArray = profileSettingsArray.enums.filter(
        (profileSetting) => this.validateIfHasCapability(profileSetting.value.requestedCapabilities)
      && (!profileSetting.value.requestedPermission
      || this.validateIfHasPermission(profileSetting.value.requestedPermission)),
      );

      this.parentSettings = profileSettingsArray.filter((setting) => !setting.value.parent);
      this.parentSettings.sort((a, b) => a.value.position - b.value.position);

      const childSettings = profileSettingsArray.filter((setting) => setting.value.parent);
      const finalChildSettings = [];
      childSettings.forEach((settingParam) => {
        const setting = settingParam;
        setting.value.selectedFrequency = setting.value.defaultFrequency;
        setting.value.filesOlderThan = setting.value.defaultFrequency;
        setting.value.selectedOption = setting.value.default && setting.value.default.value;
        finalChildSettings.push(setting);
      });

      this.childSettings = finalChildSettings;
      this.newProfile.apps = [];
      this.editProfile();
    },
    async deployConfiguration() {
      this.savingData = true;
      const params = {
        companyId: this.currentCompany.id,
        name: this.newProfile.name,
        customDefaultApps: this.newProfile.apps,
        updateHours: this.getUpdateHours,
        localAccountName: this.localAccount.AccountName,
        localAccountDefaultPassword: this.localAccount.DefaultPassword,
        localAccountPasswordAutoChange: this.localAccount.AutoChange,
        localAccountPasswordAge: this.localAccount.PasswordAge,
        localAccountPasswordLength: this.localAccount.PasswordLength,
        localAccountPasswordComplexity: this.localAccount.PasswordComplexity,
        localAccountPasswordChangeOnReveal: {
          enabled: this.localAccount.changeOnReveal.enabled,
          frequency: this.localAccount.changeOnReveal.frequency,
        },
        resetLoginKeyChain: this.localAccount.resetLoginKeyChain,
        biosDefaultPassword: this.biosPwdMng.DefaultPassword,
        biosPasswordAutoChange: this.biosPwdMng.AutoChange,
        biosPasswordAge: this.biosPwdMng.PasswordAge,
        biosPasswordLength: this.biosPwdMng.PasswordLength,
        biosPasswordComplexity: this.biosPwdMng.PasswordComplexity,
        biosPasswordChangeOnReveal: {
          enabled: this.biosPwdMng.changeOnReveal.enabled,
          frequency: this.biosPwdMng.changeOnReveal.frequency,
        },
        hewlettPackardBootOrder: this
          .bootOrderSettings[bootOrderVendors.HEWLETT_PACKARD.key].devices,
        dellBootOrder: this.bootOrderSettings[bootOrderVendors.DELL.key].devices,
        lenovoBootOrder: this.bootOrderSettings[bootOrderVendors.LENOVO.key].devices,
      };
      this.childSettings.forEach((setting) => {
        if (setting.key === profileSettings.windowsAdminPassword.key) {
          params[setting.value.id] = {
            enabled: this.localAccount.PasswordComplexity.length > 0
            && this.localAccount.PasswordLength > 0,
            frequency: setting.value.selectedFrequency ? this.frequenciesArray
              .find((x) => x.id === setting.value.selectedFrequency).minutes : null,
          };
        } else if (setting.key === profileSettings.agentVersion.key) {
          params[setting.value.id] = this.agentVersion;
        } else if (setting.key === profileSettings.biosPassword.key) {
          params[setting.value.id] = {
            enabled: this.biosPwdMng.DefaultPassword !== ''
              && this.biosPwdMng.PasswordComplexity.length > 0,
            frequency: setting.value.selectedFrequency ? this.frequenciesArray
              .find((x) => x.id === setting.value.selectedFrequency).minutes : null,
          };
        } else if (setting.key === profileSettings.turnWifiOnTemporarily.key) {
          params[setting.value.id] = setting.value.enabled;
        } else if (setting.key === profileSettings.cleanDownloadsFolder.key
                    || setting.key === profileSettings.cleanDocumentsFolder.key
                    || setting.key === profileSettings.cleanDesktopFolder.key
                    || setting.key === profileSettings.cleanPicturesFolder.key
                    || setting.key === profileSettings.cleanMusicFolder.key
                    // || setting.key === profileSettings.cleanRecycleBinFolder.key
                    || setting.key === profileSettings.cleanVideosFolder.key) {
          params[setting.value.id] = {
            filesOlderThan: setting.value.filesOlderThan ? this.frequenciesArray
              .find((x) => x.id === setting.value.filesOlderThan).id : this.frequencyEnum
              .OFF.value.id,
            typeOfDelete: setting.value.typeOfDelete ? this.typeOfDeleteArray
              .find((x) => x.id === setting.value.typeOfDelete).id : this
              .parametersToDeleteFilesEnum.PERMANENTLY_DELETE.value.id,
          };
        } else if (setting.value.dropdownOptions) {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            selectedOption: setting.value.selectedOption,
          };
        } else if (setting.value.chooseWallpaper) {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            wallpaperUrl: this.wallpaperUrl,
            wallpaperFile: this.wallpaperFile ? this.wallpaperFile.name : '',
          };
        } else if (setting.key === profileSettings.locationBounds.key) {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            perimeters: this.getLocationBounds,
          };
        } else if (setting.key === profileSettings.addDisclaimerOnSignIn.key) {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            title: this.disclaimerOnSignIn.title,
            message: this.disclaimerOnSignIn.message,
          };
        } else if (setting.key === profileSettings.lockDeviceAfterXTime.key) {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            time: parseInt(this.lockDeviceAfterXTime.time, 10),
            unit: this.lockDeviceAfterXTime.unit,
          };
        } else {
          params[setting.value.id] = {
            enabled: setting.value.enabled,
            frequency: setting.value.selectedFrequency ? this.frequenciesArray
              .find((x) => x.id === setting.value.selectedFrequency).minutes : null,
          };
        }
      });
      if (!this.$route.params.profileId
      || (this.$route.params.profileId && this.$route.params.duplicate)) {
        await this.createEmployeeProfile([params, paramsForServer({
          devicesToUpdate: this.checkedRows.map((x) => x.id),
          duplicate: this.duplicate,
          prevName: this.newProfile.prevName,
        })]);
        await this.emitToAgent({
          query: {
            type: emitToAgentEnum.EMIT_EXECUTION.value.id,
            executionId: executionEnums.UPDATE_AGENT_ENVIRONMENT_VARIABLES.value.id,
            devicesId: this.checkedRows.map((x) => x.id),
          },
        });
      } else {
        try {
          await this.updateEmployeeProfile([this.$route.params.profileId, params, paramsForServer({
            originalDevicesIds: this.selectedDevice.map((x) => x.id),
            devicesToUpdate: this.checkedRows.map((x) => x.id),
          })]);
          await this.emitToAgent({
            query: {
              type: emitToAgentEnum.EMIT_EXECUTION.value.id,
              executionId: executionEnums.UPDATE_AGENT_ENVIRONMENT_VARIABLES.value.id,
              devicesId: [...new Set([...this.selectedDevice.map((x) => x.id),
                ...this.checkedRows.map((x) => x.id)])],
            },
          });
          snackBarMessage.showSuccess('The settings have been applied.');
        } catch (error) {
          snackBarMessage.showError(error);
        }
      }
      this.savingData = false;
      this.$router.push(`/${this.currentCompany.id}/groups`);
    },
    async editProfile() {
      if (this.$route.params.profileId) {
        await this.getEmployeeProfile(this.$route.params.profileId).then((Response) => {
          this.newProfile = Response;
          this.newProfile.prevName = Response.name;
          if (this.$route.params.duplicate) {
            this.newProfile.name = '';
            this.duplicate = true;
          }
          this.findAllAssociatedDevices();
          this.newProfile.customDefaultApps = this.newProfile.customDefaultApps === null
            ? [] : this.newProfile.customDefaultApps;
          this.agentVersion = this.newProfile.agentVersion;
          this.newProfile.apps = this.newProfile.customDefaultApps;
          this.applications = this.newProfile.customDefaultApps.length;
          this.childSettings.forEach((setting, index) => {
            if (![profileSettings.windowsAdminPassword.key, profileSettings.biosPassword.key,
              profileSettings.freezeExclusions.key].includes(setting.key)) {
              if (setting.key === profileSettings.agentVersion.key) {
                this.childSettings[index].value.enabled = true;
              } else if (setting.key === profileSettings.turnWifiOnTemporarily.key) {
                this.childSettings[index].value.enabled = Response[setting.value.id];
              } else if (this.childSettings[index].value.chooseWallpaper) {
                this.childSettings[index].value.enabled = Response[setting.value.id].enabled;
                this.wallpaperUrl = Response[setting.value.id].wallpaperUrl;
                this.wallpaperFile = Response[setting.value.id].wallpaperFile
                  ? { name: Response[setting.value.id].wallpaperFile }
                  : null;
              } else {
                this.childSettings[index].value.enabled = Response[setting.value.id].enabled;
                this.childSettings[index].value.selectedFrequency = Response[setting.value.id]
                  .frequency ? this.frequenciesArray.find(
                    (x) => x.minutes === Response[setting.value.id].frequency,
                  ).id : null;
                this.childSettings[index].value.selectedOption = Response[setting.value.id]
                  .selectedOption || null;
              }
            }
            if (setting.key === profileSettings.cleanDownloadsFolder.key
              || setting.key === profileSettings.cleanDocumentsFolder.key
              || setting.key === profileSettings.cleanDesktopFolder.key
              || setting.key === profileSettings.cleanPicturesFolder.key
              || setting.key === profileSettings.cleanMusicFolder.key
              // || setting.key === profileSettings.cleanRecycleBinFolder.key
              || setting.key === profileSettings.cleanVideosFolder.key) {
              this.childSettings[index].value.filesOlderThan = Response[setting.value.id]
                .filesOlderThan;
              this.childSettings[index].value.typeOfDelete = Response[setting.value.id]
                .typeOfDelete;
            }
          });
          this.localAccount.AccountName = this.newProfile.localAccountName;
          this.localAccount.DefaultPassword = this.newProfile.localAccountDefaultPassword;
          this.localAccount.AutoChange = this.newProfile.localAccountPasswordAutoChange;
          this.localAccount.PasswordAge = this.newProfile.localAccountPasswordAge;
          this.localAccount.PasswordLength = this.newProfile.localAccountPasswordLength;
          this.localAccount.PasswordComplexity = this.newProfile.localAccountPasswordComplexity;
          this.localAccount.changeOnReveal = this.newProfile.localAccountPasswordChangeOnReveal;
          this.localAccount.resetLoginKeyChain = this.newProfile.resetLoginKeyChain;
          this.biosPwdMng.DefaultPassword = this.newProfile.biosDefaultPassword;
          this.biosPwdMng.AutoChange = this.newProfile.biosPasswordAutoChange;
          this.biosPwdMng.PasswordAge = this.newProfile.biosPasswordAge;
          this.biosPwdMng.PasswordLength = this.newProfile.biosPasswordLength;
          this.biosPwdMng.PasswordComplexity = this.newProfile.biosPasswordComplexity;
          this.biosPwdMng.changeOnReveal = this.newProfile.biosPasswordChangeOnReveal;
          this.bootOrderSettings[bootOrderVendors.HEWLETT_PACKARD.key]
            .devicesAmount = this.newProfile.hewlettPackardBootOrder.length;
          this.bootOrderSettings[bootOrderVendors.HEWLETT_PACKARD.key]
            .devices = this.newProfile.hewlettPackardBootOrder;
          this.bootOrderSettings[bootOrderVendors.DELL.key]
            .devicesAmount = this.newProfile.dellBootOrder.length;
          this.bootOrderSettings[bootOrderVendors.DELL.key]
            .devices = this.newProfile.dellBootOrder;
          this.bootOrderSettings[bootOrderVendors.LENOVO.key]
            .devicesAmount = this.newProfile.lenovoBootOrder.length;
          this.bootOrderSettings[bootOrderVendors.LENOVO.key]
            .devices = this.newProfile.lenovoBootOrder;
          this.disclaimerOnSignIn.title = this.newProfile.addDisclaimerOnSignIn.title;
          this.disclaimerOnSignIn.message = this.newProfile.addDisclaimerOnSignIn.message;
          this.lockDeviceAfterXTime.time = this.newProfile.lockDeviceAfterXTime.time;
          this.lockDeviceAfterXTime.unit = this.newProfile.lockDeviceAfterXTime.unit;
        }).catch((error) => { throw new Error(error); });
        this.updateHours(this.newProfile.updateHours);
        this.updateLocationBounds(this.newProfile.locationBounds.perimeters);
      } else {
        this.updateHours({ defaultTime: true, startTime: null, endTime: null });
        this.updateLocationBounds([]);
      }
      this.loadingData = false;
    },
    addBootOrder(x) {
      this.bootOrderSettings[x.key].devicesAmount += 1;
      this.$set(this.bootOrderSettings, x.key, this.bootOrderSettings[x.key]);
      this.bootOrderSettings[x.key].devices.push(null);
    },
    removeBootOrder(index, x) {
      for (let i = index; i < this.bootOrderSettings[x.key].devicesAmount - 1; i += 1) {
        this.bootOrderSettings[x.key].devices[i] = this.bootOrderSettings[x.key].devices[i + 1];
      }
      this.bootOrderSettings[x.key].devices.pop();
      this.bootOrderSettings[x.key].devicesAmount -= 1;
    },
    addExclusion() {
      this.uwfExclusions.pathsAmount += 1;
      this.uwfExclusions.paths.push(null);
    },
    removeExclusion(index) {
      for (let i = index; i < this.uwfExclusions.pathsAmount - 1; i += 1) {
        this.uwfExclusions.paths[i] = this.uwfExclusions.paths[i + 1];
      }
      this.uwfExclusions.paths.pop();
      this.uwfExclusions.pathsAmount -= 1;
    },
    addApp() {
      this.applications += 1;
      this.newProfile.apps.push({ name: '', version: '' });
    },
    removeApp(index) {
      for (let i = index; i < this.applications - 1; i += 1) {
        this.newProfile.apps[i] = this.newProfile.apps[i + 1];
      }
      this.newProfile.apps.pop();
      this.applications -= 1;
    },
    openCheckUpdateHourModal() {
      this.$buefy.modal.open({
        parent: this,
        component: settingUpdateTimeModal,
        hasModalCard: true,
      });
    },
    openLocationBoundsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: locationBoundsModal,
        width: window.innerWidth * 0.9,
      });
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipDevices = (this.currentPage - 1) * this.limitDevices;
    },
    changeSelectedPagination(page) {
      if (page) {
        this.currentSelectedPage = page;
      }
      this.skipSelectedDevices = (this.currentSelectedPage - 1) * this.limitSelectedDevices;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
    updateFuseData(data) {
      this.fuse = new Fuse(data, fuseOptions);
    },
    search: debounce(function search() {
      if (this.searchInput.store) {
        this.searchInput.api = `%${this.searchInput.store.toLowerCase()}%`;
      } else {
        this.searchInput.api = '';
      }
    }, 500),
    async findAllAssociatedDevices() {
      if (!this.$route.params.profileId || (this.$route.params.duplicate)) {
        return;
      }
      const query = {
        companyId: this.$route.params.companyId,
        enabled: true,
        employeeProfileId: this.$route.params.profileId,
        $select: ['id', 'name', 'model', 'hardwareSerial', 'vendor'],
        $sort: { name: [1, 1] },
      };
      if (this.newProfile.isDefaultProfile) {
        query.employeeProfileId = {
          $or: [null, this.newProfile.id],
        };
      }
      const { data } = await this.findAllDevices({ query });
      this.selectedDevice = JSON.parse(JSON.stringify(data));
      this.checkedRows = data;
    },
    onCheckRow(value, data) {
      if (value) {
        this.checkedRows.push(data);
      } else {
        const index = this.checkedRows.findIndex((x) => x.id === data.id);
        if (index !== -1) {
          this.uncheckedRows.push(this.checkedRows.splice(index, 1));
        }
      }
    },
    onCheckAll(value) {
      if (value) {
        this.checkedRows = this.devicesResult.map((x) => ({
          id: x.id,
          name: x.name,
          model: x.model,
          hardwareSerial: x.hardwareSerial,
          vendor: x.vendor,
        }));
      } else {
        this.checkedRows = [];
      }
    },
    filterFrequencies(setting) {
      const filteredFrecuencies = this.frequenciesArray.filter(
        (frequency) => setting.value.minFrequency <= frequency.minutes
            && setting.value.maxFrequency >= frequency.minutes
            && frequency.views.includes(this.currentView),
      );
      return filteredFrecuencies;
    },
    getRightFrequencyArticle(frequency) {
      let text = '';
      if (frequency === this.frequencyEnum.ONE_WEEK.value.minutes
      || frequency === this.frequencyEnum.ONE_MONTH.value.minutes
      || frequency === this.frequencyEnum.ONE_YEAR.value.minutes
      || frequency === this.frequencyEnum.TWENTY_FOUR_HOURS.value.minutes) {
        text = 'a';
      } else if (frequency === this.frequencyEnum.ONE_HOUR.value.minutes) {
        text = 'an';
      }
      return text;
    },
    async uploadWallpaperResult() {
      if (!this.wallpaperFile) {
        return;
      }
      this.isLoadingImage = true;

      const formData = new FormData();
      formData.append('file', this.wallpaperFile);
      formData.append('companyId', this.currentCompany.id);
      formData.append('groupId', this.$route.params.profileId);

      try {
        const apiUrl = new URL(this.bgApi);
        apiUrl.pathname = this.bgApiPath;

        const result = await this.axios.post(`${apiUrl}uploadwallpaper`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (result.status !== 200) {
          throw new Error(result.data);
        }
        this.isLoadingImage = false;
        this.wallpaperUrl = result.data.url;
      } catch (error) {
        snackBarMessage.showError('Unexpected error uploading the file');
        throw error;
      }
    },
    removeWallpaper() {
      this.wallpaperFile = null;
      this.wallpaperUrl = '';
    },
    getTextOptions(frequency) {
      if (frequency.id === frequencies.OFF.value.id) return 'Off';
      const article = this.getRightFrequencyArticle(frequency.minutes) || '';
      return `Older than ${article} ${frequency.name}`;
    },
    blockResetKeyChain() {
      if (this.localAccount.AccountName) {
        this.localAccount.resetLoginKeyChain = false;
        this.disableResetLoginKeyChain = true;
        return;
      }
      this.localAccount.resetLoginKeyChain = false;
      this.disableResetLoginKeyChain = false;
    },
  },
};
</script>

<style scoped>

.card-form-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 800px;
}

.b-table .table .checkbox-cell {
  max-width: 40px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.inner-element {
  display: block !important;
  margin-left: 55px;
}

.inner-without-tab {
  display: block !important;
}

.centerCardVertical {
  height: 100%;
}

.center-card {
  margin: 0 auto;
}

.first-align {
  margin-left: 0.5em
}
.second-align {
  margin-left: 2em
}
</style>

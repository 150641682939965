const bgEnums = require('../../../cross/index').enum;

const { locationPerimeterType } = bgEnums.location;
export default {
  parseLatLng(loc) {
    const latLng = loc.split(',');
    if (latLng.length === 2) return { lat: Number(latLng[0]), lng: Number(latLng[1]) };
    if (latLng.length === 4) {
      return { lat: Number(`${latLng[0]}.${latLng[1]}`), lng: Number(`${latLng[2]}.${latLng[3]}`) };
    }
    return null;
  },
  parsePerimetersFromDb(dbPerimeters) {
    const perimeters = [];
    dbPerimeters.forEach((perimeter, index) => {
      if (perimeter.type === locationPerimeterType.POLYGON.value.dbValue) {
        perimeters.push({
          name: `Geo-fence ${index + 1}`,
          value: index,
          type: locationPerimeterType.POLYGON.value,
          paths: perimeter.paths,
          center: { lat: null, lng: null },
          radius: null,
        });
      }
      if (perimeter.type === locationPerimeterType.MARKER.value.dbValue) {
        perimeters.push({
          name: `Geo-fence ${index + 1}`,
          value: index,
          type: locationPerimeterType.MARKER.value,
          paths: [],
          center: perimeter.center,
          radius: perimeter.radius,
        });
      }
    });
    return perimeters;
  },
  parsePerimetersToDb(perimeters) {
    return perimeters.map((x) => {
      if (x.type.dbValue === locationPerimeterType.POLYGON.value.dbValue) {
        return {
          type: x.type.dbValue,
          paths: x.paths,
        };
      }
      return {
        type: x.type.dbValue,
        center: x.center,
        radius: x.radius,
      };
    });
  },
  verifyPerimeters(perimeters) {
    return perimeters.every(((perimeter) => {
      if (perimeter.type === locationPerimeterType.POLYGON.value.dbValue) {
        return perimeter.paths.length > 0;
      }
      return perimeter.center && perimeter.radius;
    }));
  },
  setPolygonPaths(mvcPaths) {
    const paths = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < mvcPaths.getLength(); i++) {
      const path = [];
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < mvcPaths.getAt(i).getLength(); j++) {
        const point = mvcPaths.getAt(i).getAt(j);
        path.push({ lat: point.lat(), lng: point.lng() });
      }
      paths.push(path);
    }
    return paths;
  },
};

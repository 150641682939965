<template>
  <div id="over_map">
    <b-collapse
      v-if="validateIfHasPermission([permissions.GEOFENCING.value.dbValue])"
      class="card"
      :open="false"
      animation="slide"
      aria-id="contentIdForA11y3"
      style="width:300px; max-height: 400px;"
      @open="selectedPerimeter= 0"
      @close="selectedPerimeter = null"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="props.open"
        >
          <p
            class="card-header-title"
            style="flex-wrap: wrap; gap:5px 5px;"
          >
            <b-icon
              icon="target"
            /> Manage geo-fences
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'"
            />
          </a>
        </div>
      </template>

      <div class="custom-card">
        <div
          class="force-right"
          style="flex-wrap: wrap; gap:5px 5px; margin-bottom: 10px;"
        >
          <b-button
            class="button is-primary"
            icon-right="content-save"
            size="is-small"
            @click="savePerimeters()"
          />
          <b-button
            class="button is-primary"
            icon-right="plus"
            size="is-small"
            @click="addNewPath()"
          />
          <b-button
            v-if="perimeters.length > 0"
            class="button is-secondary"
            icon-right="delete"
            size="is-small"
            @click="removePath()"
          />
        </div>
        <b-field
          group-multiline
          grouped
        >
          <b-field
            v-if="perimeters.length > 0"
            horizontal
            style="margin-bottom:10px"
          >
            <template #label>
              Geo&#8209;fence
            </template>
            <b-dropdown
              v-if="selectedPerimeter !== null"
              v-model="selectedPerimeter"
              aria-role="list"
            >
              <template #trigger="{ active }">
                <b-button
                  style="width: 150px !important"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                  :label="perimeters.length > 0 ? perimeters[selectedPerimeter].name
                    : 'Select geo-fence'"
                />
              </template>
              <b-dropdown-item
                v-for="(perimeter, index) in perimeters"
                :key="index"
                aria-role="listitem"
                :value="perimeter.value"
              >
                {{ perimeter.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
          <b-field
            v-if="perimeters.length > 0"
            style="margin-bottom:10px"
            label="Type"
            horizontal
          >
            <b-dropdown
              v-if="selectedPerimeter !== null"
              v-model="perimeters[selectedPerimeter].type"
              aria-role="list"
            >
              <template #trigger="{ active }">
                <b-button
                  :label="perimeters[selectedPerimeter].type.text"
                  :icon-left="perimeters[selectedPerimeter].type.icon"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                />
              </template>

              <b-dropdown-item
                v-for="(type, index) in locationPerimeterType.enums.map((x) => x.value)"
                :key="index"
                :value="type"
                aria-role="listitem"
              >
                <div class="media">
                  <b-icon
                    class="media-left"
                    :icon="type.icon"
                  />
                  <div class="media-content">
                    <h3>{{ type.text }}</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </b-field>
        <div
          v-if="perimeters.length > 0 && selectedPerimeter !== null
            && perimeters[selectedPerimeter].type.dbValue
              === locationPerimeterType.MARKER.value.dbValue"
        >
          <hr>
          <b-field
            style="margin-bottom:10%"
          >
            <b-input
              v-model="searchAddressInput.address"
              expanded
              placeholder="Address"
              type="text"
              size="is-small"
            />
            <b-input
              v-model="searchAddressInput.city"
              size="is-small"
              placeholder="City"
              type="text"
            />
            <b-button
              class="button"
              icon-right="magnify"
              size="is-small"
              @click="searchLocation()"
            />
          </b-field>
          <b-field
            label="Center"
            horizontal
            grouped
            class="center"
          >
            <b-field
              label="Latitude"
              label-position="on-border"
            >
              <b-input
                v-model="perimeters[selectedPerimeter].center.lat"
                type="text"
              />
            </b-field>
            <b-field
              label="Longitude"
              label-position="on-border"
            >
              <b-input
                v-model="perimeters[selectedPerimeter].center.lng"
                type="text"
              />
            </b-field>
          </b-field>
          <b-field
            label="Radius"
            horizontal
            class="radius"
            style="width: 65px;"
          >
            <b-input
              v-model="perimeters[selectedPerimeter].radius"
              type="text"
              style="width: 65px;"
            /> <p>
              <strong>m</strong>
            </p>
          </b-field>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps';
import RoleMixin from '@/mixins/roles';
import CompanyMixin from '@/mixins/company';
import toastMessage from '@/helpers/toastMessage';
import bgEnums from '../../../../cross';

const { locationPerimeterType } = bgEnums.enum.location;
const { permissions } = bgEnums.enum.roles;

export default {
  name: 'ManageGeofences',
  mixins: [CompanyMixin, RoleMixin],
  props: {
    perimeters: {
      type: Array,
      default: () => [],
    },
    onUpdateParameters: {
      type: Function,
      required: true,
    },
    onSavePerimeters: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchAddressInput: {
        address: '',
        city: '',
      },
      locationPerimeterType,
      selectedPerimeter: null,
      isOpen: false,
      permissions,
    };
  },
  computed: {
    google: gmapApi,
  },
  watch: {
    perimeters: {
      async handler(data) {
        if (!data) {
          return;
        }
        this.onUpdateParameters({
          perimeters: data,
        });
      },
      deep: true,
    },
    selectedPerimeter: {
      async handler(data) {
        if (data === undefined) {
          return;
        }
        this.onUpdateParameters({
          selectedPerimeter: data,
        });
      },
      deep: true,
    },
  },
  methods: {
    searchLocation() {
      if (!this.searchAddressInput.address || !this.searchAddressInput.city) {
        toastMessage.showError('You must specify the address and the city to search.');
        return;
      }
      toastMessage.showSuccess('Looking for location', 1000);
      const geocoder = new this.google.maps.Geocoder();
      const address = `${this.searchAddressInput.address}, ${this.searchAddressInput.city}`;
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const perimeters = JSON.parse(JSON.stringify(this.perimeters));
          perimeters[this.selectedPerimeter].center = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          this.onUpdateParameters({
            perimeters,
            mapCenter: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            zoom: 15,
          });
        }
      });
    },
    addNewPath() {
      const index = this.perimeters.length;
      const perimeters = JSON.parse(JSON.stringify(this.perimeters));
      perimeters.push({
        name: `Geo-fence ${index + 1}`,
        value: index,
        type: this.locationPerimeterType.POLYGON.value,
        paths: [],
        center: { lat: null, lng: null },
        radius: 500,
      });
      this.selectedPerimeter = index;
      this.onUpdateParameters({
        perimeters,
      });
    },
    removePath() {
      if (this.perimeters.length === 0) {
        return;
      }
      this.perimeters.splice(this.selectedPerimeter, 1);
      const newPerimeters = [];
      this.perimeters.forEach((x, index) => {
        newPerimeters.push({
          name: `Geo-fence ${index + 1}`,
          value: index,
          type: x.type,
          paths: x.paths,
          center: x.center,
          radius: x.radius,
        });
      });
      this.selectedPerimeter = 0;
      this.onUpdateParameters({
        perimeters: newPerimeters,
      });
    },
    savePerimeters() {
      this.onSavePerimeters();
    },
  },
};
</script>

<style>
.custom-card {
  padding:10px;
}
#over_map { position: absolute; top: 10px; left: 10px; z-index: 0; }

.label.label {
  width: 60px;
  display: flex !important;
}
#contentIdForA11y3 > div > div:nth-child(3) >
div.field.radius.is-horizontal > div.field-body > div:nth-child(2) {
  padding-top: 5px;
}

#contentIdForA11y3 > div > div:nth-child(3) > div:nth-child(2) >
div > div > div.control.is-small.is-expanded.is-clearfix > input {
  width: 168px;
}

</style>

import vtulEnums from '../../../cross/index';

const { planCapabilities } = vtulEnums.enum.planCapabilities;

export default [
  {
    position: 1,
    id: 'name',
    title: 'Name',
    field: 'name',
    date: false,
    timeAgo: false,
    disabled: true,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 2,
    id: 'status',
    title: 'Agent',
    field: 'status',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 3,
    id: 'amtStatus',
    title: 'AMT',
    field: 'amtStatus',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.AMT_MANAGEMENT.value],
  },
  {
    position: 4,
    id: 'lastSeenUnified',
    title: 'Last seen',
    field: 'lastSeenUnified',
    date: false,
    timeAgo: true,
    disabled: false,
    isAMT: false,
    isAgent: true,
    isSortableColumn: false,
    isDataBaseColumn: false,
    capability: [planCapabilities.AMT_MANAGEMENT.value],
  },
  {
    position: 5,
    id: 'lastSeen',
    title: 'Agent last seen',
    field: 'lastSeen',
    date: false,
    timeAgo: true,
    disabled: false,
    isAMT: false,
    isAgent: true,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 6,
    id: 'amtLastSeen',
    title: 'AMT last seen',
    field: 'amtLastSeen',
    date: false,
    timeAgo: true,
    disabled: false,
    isAMT: true,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: true,
    capability: [planCapabilities.AMT_MANAGEMENT.value],
  },
  {
    position: 7,
    id: 'lastUser',
    title: 'Last user',
    field: 'lastUser',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 8,
    id: 'description',
    title: 'Description',
    field: 'description',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 9,
    id: 'city',
    title: 'City',
    field: 'city',
    date: false,
    timeAgo: false,
    disabled: false,
    location: true,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 10,
    id: 'region',
    title: 'Region',
    field: 'region',
    date: false,
    timeAgo: false,
    disabled: false,
    location: true,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 11,
    id: 'country',
    title: 'Country',
    field: 'country',
    date: false,
    timeAgo: false,
    disabled: false,
    location: true,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 12,
    id: 'publicIp',
    title: 'Public IP',
    field: 'publicIp',
    date: false,
    timeAgo: false,
    disabled: false,
    location: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 13,
    id: 'address',
    title: 'Approximate address',
    field: 'address',
    date: false,
    timeAgo: false,
    disabled: false,
    location: true,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 14,
    id: 'hardwareSerial',
    title: 'Serial',
    field: 'hardwareSerial',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 15,
    id: 'model',
    title: 'Model',
    field: 'model',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 16,
    id: 'vendor',
    title: 'Manufacturer',
    field: 'vendor',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 17,
    id: 'type',
    title: 'Type',
    field: 'type',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 18,
    id: 'lockStatus',
    title: 'Locked',
    field: 'lockStatus',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: true,
    capability: [planCapabilities.DEVICES_MANAGEMENT.value],
  },
  {
    position: 19,
    id: 'OS',
    title: 'OS',
    field: 'OS',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 20,
    id: 'OSVendor',
    title: 'OS Vendor',
    field: 'OSVendor',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 21,
    id: 'osArch',
    title: 'Architecture',
    field: 'osArch',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 22,
    id: 'agentVersion',
    title: 'Agent version',
    field: 'agentVersion',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  {
    position: 23,
    id: 'lastSeenDate',
    title: 'Agent last seen date',
    field: 'lastSeenDate',
    date: true,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: true,
    isSortableColumn: true,
    isDataBaseColumn: false,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 24,
    id: 'amtLastSeenDate',
    title: 'AMT last seen Date',
    field: 'amtLastSeenDate',
    date: true,
    timeAgo: false,
    disabled: false,
    isAMT: true,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: false,
    capability: [planCapabilities.AMT_MANAGEMENT.value],
  },
  {
    position: 25,
    id: 'lastSeenDateUnified',
    title: 'Last seen date',
    field: 'lastSeenDateUnified',
    date: true,
    timeAgo: false,
    disabled: false,
    isAMT: true,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: false,
    capability: [planCapabilities.AMT_MANAGEMENT.value],
  },
  {
    position: 26,
    id: 'deviceUsers',
    title: 'Users',
    field: 'deviceUsers',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: false,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 27,
    id: 'tags',
    title: 'Tags',
    field: 'tags',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 28,
    id: 'deviceGroup',
    title: 'Group',
    field: 'deviceGroup',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: false,
    isDataBaseColumn: false,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 29,
    id: 'lastLocated',
    title: 'Last located',
    field: 'lastLocated',
    date: true,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 30,
    id: 'locationCreatedAt',
    title: 'Time in that location',
    field: 'locationCreatedAt',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isDateHumanize: true,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value],
  },
  {
    position: 31,
    id: 'encryptionStatus',
    title: 'BitLocker',
    field: 'encryptionStatus',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  {
    position: 32,
    id: 'biosManagementStatus',
    title: 'BIOS/UEFI',
    field: 'biosManagementStatus',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
    ],
  },
  {
    position: 33,
    id: 'freezeStatus',
    title: 'Freeze status',
    field: 'freezeStatus',
    date: false,
    timeAgo: false,
    disabled: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [
      planCapabilities.UWF.value,
    ],
  },
  {
    position: 34,
    id: 'ipv4',
    title: 'Internal IPs',
    field: 'ipv4',
    date: false,
    timeAgo: false,
    disabled: false,
    location: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  {
    position: 35,
    id: 'macAddress',
    title: 'Mac addresses',
    field: 'macAddress',
    date: false,
    timeAgo: false,
    disabled: false,
    location: false,
    isAMT: false,
    isAgent: false,
    isSortableColumn: true,
    isDataBaseColumn: true,
    capability: [planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
];

<template>
  <card
    slot="external-body"
    external-card="material-card-content"
    internal-card="material-card"
    style-internal-card="flex:auto"
    style="overflow: visible;"
  >
    <div
      id="wrapper"
      slot="body"
    >
      <gmap-map
        ref="map"
        :center="center"
        class="map"
        :zoom="zoom"
        style="height:85vh;z-index:0;"
        :options="{
          'minZoom': 3,
          'maxZoom': 20,
          'streetViewControl': false,
          'mapTypeControl': false,
          'clickableIcons': false
        }"
        @click="addPath($event)"
      >
        <gmap-polygon
          v-for="(perimeter, index) in perimeters.filter(
            (x) => x.type.dbValue === locationPerimeterType.POLYGON.value.dbValue)"
          :key="index"
          ref="polygon"
          :paths="perimeter.paths"
          :editable="perimeter.value === selectedPerimeter"
          @paths_changed="updateEdited($event)"
        />
        <gmap-circle
          v-for="(perimeter) in perimeters.filter((x) =>
            x.type.dbValue === locationPerimeterType.MARKER.value.dbValue
            && x.center.lat && x.center.lng
            && x.radius)"
          :key="perimeter.name"
          :center="{lat: parseFloat(perimeter.center.lat), lng: parseFloat(perimeter.center.lng)}"
          :radius="parseFloat(perimeter.radius)"
          :visible="true"
          :editable="perimeter.value === selectedPerimeter"
          :options="{'fillColor':'red', 'fillOpacity':0.2}"
          @radius_changed="radiusChanged($event)"
          @center_changed="centerChanged($event)"
        />
      </gmap-map>
      <manage-zones
        :perimeters="perimeters"
        :on-update-parameters="updatePerimeters"
        :on-save-perimeters="saveMap"
      />
    </div>
  </card>
</template>

<script>
import { throttle } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';
import locationHelpers from '@/helpers/locationHelpers';
import toastMessage from '@/helpers/toastMessage';
import bgEnums from '../../../../cross';
import ManageGeofences from '../cross/ManageGeofences.vue';

const { locationPerimeterType } = bgEnums.enum.location;

export default {
  name: 'LocationBounds',
  metaInfo: {
    title: 'Map - Boardgent',
  },
  components: {
    card,
    'manage-zones': ManageGeofences,
  },
  data() {
    return {
      center: { lat: 38.872886, lng: -77.05472 },
      zoom: 2,
      mvcPaths: null,
      errorMessage: null,
      mapLocation: { lat: 4.7246101, lng: -74.0421224 },
      perimeters: [],
      selectedPerimeter: null,
      searchAddressInput: '',
      locationPerimeterType,
    };
  },
  computed: {
    ...mapGetters('employee-profiles', ['getLocationBounds']),
    polygonPaths() {
      if (!this.mvcPaths) return null;
      return locationHelpers.setPolygonPaths(this.mvcPaths);
    },
  },
  watch: {
    polygonPaths: throttle(function polygonPaths(paths) {
      const currentPerimeter = this.perimeters[this.selectedPerimeter];
      if (paths && currentPerimeter.type.dbValue
        === this.locationPerimeterType.POLYGON.value.dbValue) {
        [this.perimeters[this.selectedPerimeter].paths] = paths;
      }
    }, 1000),
  },
  created() {
    this.perimeters = locationHelpers.parsePerimetersFromDb(this.getLocationBounds);
  },
  methods: {
    ...mapActions('employee-profiles', ['updateLocationBounds']),
    updateEdited(mvcPaths) {
      this.mvcPaths = mvcPaths;
    },
    addPath(event) {
      if (this.perimeters.length < 1) {
        return;
      }
      const currentPerimeter = this.perimeters[this.selectedPerimeter];
      if (currentPerimeter.type.dbValue === this.locationPerimeterType.POLYGON.value.dbValue) {
        this.perimeters[this.selectedPerimeter].paths.push({
          lng: event.latLng.lng(),
          lat: event.latLng.lat(),
        });
      }
      if (this.perimeters[this.selectedPerimeter].center.lat !== null) return;
      if (currentPerimeter.type.dbValue === this.locationPerimeterType.MARKER.value.dbValue) {
        this.perimeters[this.selectedPerimeter].center = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
      }
    },
    saveMap() {
      const finalPerimeters = locationHelpers.parsePerimetersToDb(this.perimeters);
      if (locationHelpers.verifyPerimeters(finalPerimeters)) {
        this.updateLocationBounds(finalPerimeters);
        this.$parent.close();
      } else {
        toastMessage.showError('Verify that the number of perimeters on the map matches the perimeters created');
      }
    },
    updatePerimeters(data) {
      this.perimeters = data.perimeters ? data.perimeters : this.perimeters;
      this.selectedPerimeter = data.selectedPerimeter !== undefined
        ? data.selectedPerimeter : this.selectedPerimeter;
      this.center = data.mapCenter || this.center;
      this.zoom = data.zoom || this.zoom;
    },
    radiusChanged(event) {
      this.perimeters[this.selectedPerimeter].radius = event;
    },
    centerChanged(event) {
      if (this.perimeters[this.selectedPerimeter].center.lat === null
      || this.perimeters[this.selectedPerimeter].center.lng === null) {
        return;
      }
      if (this.perimeters[this.selectedPerimeter].center.lat === event.lat()
      && this.perimeters[this.selectedPerimeter].center.lng === event.lng()) {
        return;
      }
      this.perimeters[this.selectedPerimeter].center = {
        lat: event.lat(),
        lng: event.lng(),
      };
    },
  },
};
</script>

<style scoped>
#wrapper { position: relative; }
</style>
